import { render, staticRenderFns } from "./PlacesAgenda.vue?vue&type=template&id=f13fbd36&scoped=true&"
import script from "./PlacesAgenda.vue?vue&type=script&lang=js&"
export * from "./PlacesAgenda.vue?vue&type=script&lang=js&"
import style0 from "./PlacesAgenda.vue?vue&type=style&index=0&id=f13fbd36&prod&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f13fbd36",
  null
  
)

export default component.exports